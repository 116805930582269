.masterSelectionPage {
	background-color: #fff;
	position: relative;
	min-height: 100vh;
}
.page-title {
	font-weight: 700;
	font-size: 32px;
}

.page-subtitle {
	font-weight: 400;
	display: inline-block;
	max-width: 1110px;
    font-size: 18px;
    color: #222;
}

.tag-nav {
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 15px;
	margin-bottom: 15px;
}

.tag-nav .tag-item {
	flex-shrink: 0;
	background-color: #fff;
	border: 2px solid #000;
	padding: 3px 17px;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;
	margin-bottom: 10px;
}

.tag-nav .tag-item:not(:last-of-type) {
	margin-right: 20px;
}

.tag-nav .tag-item.active {
	background-color: #08F7A1;
	border-color: #08F7A1;
}

.tag-nav .tag-item a {
	text-decoration: none !important;
	color: #000 !important;
	font-weight: 600;
}
.tag-nav .tag-item.active a {
	text-decoration: none !important;
	color: #000 !important;
}
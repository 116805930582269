.card {
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.05));
    min-width: 350px;
    max-width: 350px;
    border: none !important;
    border-radius: 8px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
}

.subInfo {
    position: absolute;
    left: 16px;
    top: 16px;
    background-color: #FFFFFF;
    padding: 4px 8px;
    border-radius: 4px;
}

.subInfo span {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;  
}

div.cardBody {
    text-align: left;
    padding:24px;
    display: flex;
    flex-direction: column;
}

.cardBody p{
    margin-bottom: 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

.cardTitle {
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    margin-bottom: 16px;
}

.description{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
}

button.button, a.button {
    text-transform: uppercase;
    padding: 8px 16px !important;
    font-size: 12px !important;
    line-height: 15px !important;
    text-decoration: none;
    cursor: pointer;
}

.counterText {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin-right: 8px;
    margin-bottom: 0px !important;
}

hr.hr {
    background-color: #E6F1F0;
    border: none;
    height: 2px;
    opacity: 1;
}

.cardImage {
    width: 100%;
    height: 180px;
    max-height: 180px;
}

.cardImage img {
    object-fit: cover;
}

.cardFooter {
    margin-top: auto;
}

.cardFooter button {
    padding: 8px 16px !important;
}
.path-card {
	border-top-left-radius: 15px !important;
	border-bottom-left-radius: 15px !important;
	position: relative;
	overflow: hidden;
	border: none;
	max-width: 1200px;
	margin: 0 auto;
}

.path-card:before {
	content: '';
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	width: 24px;
}

.path-card .path-title {
	font-weight: 600;
	font-size: 21px;
	display: inline-block;
	flex-shrink: 0;
	line-height: 26px;
}

.path-card .projects-count {
	padding: 0px 15px;
    display: inline-flex;
    align-items: center;
	border: 3px solid #222;
	border-radius: 5px;
	font-size: 21px;
	font-weight: 500;
	flex-shrink: 0;
	cursor: default;
	color: #222222;
}

.path-desc {
	width: 33.333%;
}

.path-card .card-footer {
	background-color: #fff;
}

.path-card .offer-message .icon {
	font-size: 32px;
	color: #1ccd5b;
	margin-right: 10px;
	flex-shrink: 0;
}
.path-card .offer-message .all-text{
	font-size: 14px;
	line-height: 17px;
}

.path-badge {
	font-size: 12px !important;
	font-weight: 500;
	color: #222 !important;
	border: 1px solid black !important;
}
.percorsi-body-button{
	font-size: 14px;
}
.percorsi-body-text{
	font-size: 14px;
	color: #222222;
}
.percorsi-progress-header{
	font-size: 12px;
	font-weight: 400;
	color: #222222;
	/* display: flex; */
	flex-direction: column;
	justify-content: end;
	align-items: center;
	padding: 0px;
	gap: 8px;
}
.projects-count{
	width: 120px !important;
	height: 42px !important;
	font-weight: 800;
	margin-right: 10px;
}


@media (max-width:768px) {
	.btn-custom-dark, .btn-custom-light {
		padding: 0px !important;
	}
}
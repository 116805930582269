.scope-change-modal .modal-header {
	border-bottom: 0;
}
.scope-block-list {
	padding: 30px 0 0 0;
	margin: 0;
	list-style: none;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -moz-flex;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
}

.scope-block {
	flex-shrink: 0;
	padding: 5px;
	border: 3px solid #EDF1F4;
	border-radius: 10px;
	width: 116px;
    height: 116px;
	cursor: pointer;
	margin-bottom: 16px;
}


@media (min-width: 769px){
	.scope-block:not(:nth-child(6n)):not(:last-child) {
		margin-right: 16px;
	}
}
@media (max-width: 768px){
	.scope-block:not(:nth-child(3n)):not(:last-child) {
		margin-right: 16px;
	}
	.scope-block {
		width: 100px !important;
    	height: 100px !important;
	}
	.scopeBlockImage{
		width: 84px !important;
    	height: 84px !important;
	}
}


.scope-block-body {
	border-radius: 9px;
}
.scope-block .title span:first-child {
	font-size: 18px;
	font-weight: 700;
	margin-right: 5px;
	color: #fff;
}
.scope-block .title span:last-child {
	font-size: 18px;
	font-weight: 400;
	min-width: 20px;
	color: #fff;
}
.scopeBlockImage {
	width: 100px;
	height: 100px;
	border-radius: 8px;
}
.working-block {
	cursor: pointer;
	height: 104px;
}

.selected-working-sector {
	border-color: #08F7A1 !important;
}

.working-sector-modal {
	max-width: 850px !important;
}
.sector-title {
	font-weight: 500;
	font-size: 18px;
}
.working-block .text-secondary {
	font-size: 12px;
	color: #8E8E8E;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	line-height: 15px;
}
.working-block .card-img-left{
	height: 72px;
	width: 72px;
}
.working-block .title-working-block{
	font-size: 21px;
}
.working-block .text-secondary{
	font-size: 14px;
}

@media (min-width: 992px){
	.working-sector-modal.modal-lg,
	.working-sector-modal.modal-xl {
		max-width: 850px !important;
	}
}

.working-sector-modal {
	font-family: 'Montserrat', sans-serif;
}

.working-sector-modal .modal-header {
	padding: 32px;
	border-bottom: none;
}
.working-sector-modal .modal-header .modal-title {
	font-size: 21px;
	line-height: 25.6px;
	font-weight: 700;
}
.working-sector-modal .modal-body {
	padding-top: 0;
	font-size: 14px;
	line-height: 17px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 32px;
}
.working-sector-modal .working-block {
	border-radius: 8px !important;
	padding: 16px !important;
	box-sizing: border-box;
}
.working-sector-modal .working-block:not(.selected-working-sector) {
	border: 2px solid #EDF1F4 !important;
}
.working-sector-modal .working-block .sector-title {
	font-size: 18px !important;
	line-height: 22px !important;
	color: #222 !important;
}
.working-sector-modal .working-block .text-secondary {
	font-size: 12px !important;
	color: #8E8E8E !important;
}
.working-sector-modal .working-block-column {
	margin-bottom: 16px;
	padding-right: 8px;
	padding-left: 8px;
}
.working-sector-modal .working-block .working-block-body {
	display: flex;
}
.working-sector-modal .working-block .working-block-body .card-img-left{
	width: 72px;
	height: 72px;
	flex-shrink: 0;
	border-radius: 8px;
	margin-right: 16px;
}
.working-sector-modal .modal-footer {
	padding-top: 16px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 32px;
}
.working-sector-modal .modal-footer .save-btn {
	margin-top: 0;
	margin-bottom: 0;
	padding: 16px 24px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	border: none;
}
.community-page {
    background-color: #fafafa !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.community-page nav {
    display: none;
}

.community-page main {
    min-height: 100vh;
}

.community-page img {
    display: block;
    width: 100%;
    height: auto;
}

.community-title {
    font-size: 32px;
    line-height: 1.5;
    font-weight: 700;
    color: #333333;
    margin: 0;
}

.community-subtitle {
    line-height: 1.5;
    font-weight: 400;
    font-size: 18px;
}

.community-link {
    display: inline-block;
    background-color: #08f7a1;
    padding: 1rem 2rem;
    border-radius: 0.25rem;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1;
    color: #222222;
    font-weight: 600;
}

.community-link:hover {
    color: inherit;
}

@media screen and (max-width: 768px) {
    .community-page nav {
        display: block;
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,800&display=swap');

body {
	background-color: #E5E5E5 !important;
	font-family: 'Montserrat', sans-serif !important;
	display: flex;
    flex-direction: column;
}

.height-full-screen {
	height: 100vh;
}

button:focus {
	box-shadow: none !important;
}

.btn-custom-dark, .btn-custom-light {
	border-radius: 4px !important;
	display: block !important;
	color: #222 !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 17px !important;
	padding: 16px 32px !important;
	text-align: center !important;
}

.btn-custom-dark {
	background-color: #08F7A1 !important;
	border: none!important;
}
.btn-custom-dark:hover {
	background: rgb(8,247,161);
	background: linear-gradient(180deg, rgba(8,247,161,1) 0%, rgba(0,115,105,1) 100%);
}

.btn-custom-light {
	background-color: #fff !important;
	border: 1px solid #222 !important;
}
.btn-custom-light:hover {
	border: 1px solid #08F7A1 !important;
	color: #08F7A1 !important;
}

.btn-custom-dark:disabled, .btn-custom-light:disabled {
	opacity: 0.6;
	cursor: default;
}

.badge-success {
	color: black;
	background-color: #08F7A1;
}

.badge-danger {
	color: #fff;
	background-color: #dc3545;
}


/******** icon UI sidebar menu on mobile ********/


/* @media (max-width:1024px) { */
    .menu-toggle-btn {
        display: block;
    }
/* @media (min-width:1025px) {
    .menu-toggle-btn {
        display: none !important;
    }
} */

.menu-toggle-btn  {
    width: 56px;
    height: 56px !important;
    background: #fff !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
    border: none !important;
    color: #8E8E8E !important;
    position: relative;
}

.menu-toggle-icon {
    font-size: 28px;
}

.modal-header .btn-close {
    color: #EE595E !important;
}


hr {
	margin-top: 7px;
	margin-bottom: 7px;
	color: #E6F1F0;	
}

.btn-modal {
	font-size: 14px;
	font-weight: 600;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;

	width: 197px;
	height: 49px;

	background: #08F7A1;
	border-radius: 4px;
	border: none;
}
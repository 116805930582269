.footer {
    background-color: #222 !important;
}
.footer a {
    text-decoration: none;
    color: #fff;
}

.footer-socials {
    text-align: right;
    font-size: 30px;
}

footer .green, .footer a:hover  {
    color: #08F7A1 !important;
}

.footer i {
    margin-right: 2rem;
}
.course-page {
	background-color: #FAFAFA;
	position: relative;
	min-height: 100vh;
	padding-bottom: 80px;
}

.title {
	width: 100% !important;
}

.page-subtitle {
	font-weight: 400;
	display: inline-block;
	max-width: 1110px;
    font-size: 18px;
    color: #222;
}

.tag-nav {
	width: 100%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 15px;
	margin-bottom: 15px;
}

.tag-nav .tag-item {
	flex-shrink: 0;
	background-color: #fff;
	border: 2px solid #000;
	padding: 3px 17px;
	border-radius: 8px;
	font-size: 14px;
	cursor: pointer;
	margin-bottom: 10px;
}

.tag-nav .tag-item:not(:last-of-type) {
	margin-right: 20px;
}

.tag-nav .tag-item.active {
	background-color: #08F7A1;
	border-color: #08F7A1;
}

.tag-nav .tag-item a {
	text-decoration: none !important;
	color: #000 !important;
	font-weight: 600;
}
.tag-nav .tag-item.active a {
	text-decoration: none !important;
	color: #000 !important;
}

.input-label {
	font-family: Montserrat;
	font-size: 12px;
	font-weight: 400;
	line-height: 15px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 8px;
}

#searchCourse {
	width: 100%;
	display: flex;
	padding: 16px;
	background: #FFFFFF;
	border: 2px solid #E6F1F0;
	border-radius: 8px;
	font-size: 14px;
	line-height: 17px;
}

#searchCourse:focus, #searchCourse:focus-visible {
	border-color: #04b585;
	outline: none;
}

/* TODO check mobile-first */

@media (min-width:768px) {
    .input {
	width: 250px;
	}
}

.courses-container {
	margin-top: 40px;
}

.course-card-body{
	background-color: #fff;
	margin: 24px 0;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
}

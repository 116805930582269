canvas { 
    z-index: 999999 !important; 
}

.badge-pill {
    border-radius: 100px !important;
    font-size: 12px !important;
    padding: 4px 8px !important;
}

.guide-card {
	border: none;
	border-radius: 8px !important;
}
.guide-card.custom-border {
	border: 2px solid #EDF1F4 !important;
}

.guide-card .card-header {
	background-color: #fff;
	border-radius: calc(10px - 1px) calc(10px - 1px) 0 0;
}

.guide-card .card-title {
	font-weight: 800;
	color: #222;
	font-size: 18px;
}

.guide-card .card-header .card-title {
	font-size: 20px;
	color: #8e8e8e;
	font-weight: 600;
}
.guide-card .card-header .card-title .icon {
	font-size: 28px;
}

.guide-card .card-header .delete-btn {
	color: #ee595e;
	text-decoration: none;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
}

.guide-card .card-horizontal {
	flex-direction: row;
}

.guide-card .card-img-left {
	width: 56px;
	height: 56px;
	border-radius: 8px;
}

.guide-card .card-body, .guide-card .flex-grow-1 {
	min-width: 0;
}

.guide-card .stats {
	color: #4D4D4D;
	font-weight: 500;
	font-size: 14px;
	list-style-type: none;
}

/* .guide-card .stats li {
	margin-right: 30px !important;
	margin-bottom: 5px !important;
} */

.guide-card .stats .icon {
	font-size: 20px;
}

@media (max-width: 374px) {
	.guide-card .stats li {
		margin-right: 10px !important;
	}
}

.guide-card .tag-container .tag-requirement {
	display: inline-block;
	padding: 3px 8px;
	margin-right: 5px;
	border-radius: 300px;
	font-size: 12px;
	background-color: #f5f5f5;
	cursor: default;
	color: #fff;
}

.skills h6, .skills span, .requirements-label {
	color: #4D4D4D;
	font-size: 12px;
}
.tag-skill {
	display: inline-block;
	padding: 4px 8px;
	margin-right: 5px;

	border-radius: 4px;
	border: 1px solid #4d4d4d;
	background-color: #fff;
	cursor: default;
}

.chart-card-btn, .chart-card-btn:hover {
	display: block;
	padding: 8px 16px;
	margin: 0 auto;
	background-color: #fff;
	color: #222;
	border: 1px solid #222222;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
	left: 15px;
	right: 15px;
	bottom: 5px;
	text-align: center;
	text-decoration: none;
}
.button-active {
	background-color: #08F7A1 !important;
	border: 2px solid #08F7A1 !important;
}
.button-inactive {
	background-color: #08F7A1 !important;
	border: 2px solid #08F7A1 !important;
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}

.guide-card .chart-row li {
	font-size: 14px;
	font-weight: 500;
}

.guide-card .chart-container {
	position: relative;
	width: 56px;
	margin: 10px;
}
.guide-card .chart-container .icon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 19px;
	color: #ccc;
}
/* .guide-card-footer {
	background-color: #E6F8F3;
} */

.icon-gray {
	color: #777 !important;
}
.icon-green {
	fill: url(#lgrad);
}
.icon-red {
	color: #FF5A5F !important;
}
.icon-yellow {
	color: #FFCB00 !important;
}
.icon-orange {
	color: #F2994A !important;
}

.guide-card .text-success {
	color: #79c23e !important;
}

.chart-outer-column {
	position: relative;
	padding-bottom: 44px;
}

.chart-outer-column .chart-row {
	height: 100%;
}

.guide-card .title-block .badge {
	font-size: 1rem;
	font-weight: 500;
}
.guide-card .title-block .badge-pink {
	background-color: #f85c92 !important;
}
.guide-card .title-block .badge-orange {
	background-color: #f86903 !important;
}
.label-coaches {
	font-size: 12px;
	color: #4D4D4D !important;
}
.card-description{
	font-size: 14px !important;
}
.scope-change-modal .btn-custom-dark{
	padding: 16px 24px !important;
	line-height: 17px;
}
.home-guide-chart {
	background: #FFFFFF;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	position: relative;
    padding: 8px 16px;
	gap: 10px;
}
.mt-40 {
	margin-top: 40px !important;
}
.mb-40 {
	margin-bottom: 40px !important;
}
.pt-40 {
	padding-top: 40px !important;
}
.pb-40 {
	padding-bottom: 40px !important;
}

.custom-shadow {
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
}

.guide-card-collapse-btn {
	font-size: 20px !important;
}

.pt-16 {
	padding-top: 16px !important;
}
.carousel.carousel-slider{
 padding-bottom: 24px !important;
}

@media (max-width: 768px) {
	.home-guide-chart{
		box-shadow: none !important;
	}
	.collapse-label{
		text-align: left;
	}
}

.icon-tooltip-project-blocked {
	font-size: 20px;
	color: #8E8E8E;	
}

.control-dots {
	margin: 0 !important;
}

.carousel .control-dots .dot {
	background: #EDF1F4 !important;
	opacity: 1 !important;
	box-shadow: none !important;
}

.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
	background-color: #08F7A1 !important;
}

span.requirements-final-project {
	color: gray;
	border: 1px solid gray;
	font-size: 12px;
}
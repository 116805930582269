.body-with-sidebar {
  margin-left: 240px;
  background: #fafafa;
  padding-top: 0;
  padding-bottom: 0;
}
.body-without-sidebar #root {
  margin-left: 107px !important ;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 999;
  box-shadow: 4px 0px 16px rgba(0, 0, 0, 0.05);
}
.sidebar-desktop {
  width: 250px;
}
.sidebar-desktop.collapsed {
  width: 107px;
}

.sidebar-mobile {
  width: 100%;
  max-width: 414px;
}
.sidebar-mobile.collapsed {
  display: none;
}

.btn-close-menu {
  background: #fff;
  color: #222;
  font-size: 26px;
  padding: 2px;
  height: 26px;
  width: 26px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  margin-left: auto;
  margin-top: 5px;
}

@media (max-width: 1024px) {
  #root {
    margin-left: 0 !important;
  }
  .body-with-sidebar:not(.sidebar-mobile-opened) .sidebar {
    display: none;
  }
}

/*****************************************/
/*****************************************/
/*****************************************/

.sidebar-desktop.collapsed .menu-label {
  display: none;
}
.sidebar-desktop.collapsed .sidebar-items {
  justify-content: center;
}

.sidebar-header {
  padding: 0 10px;
}
.sidebar-header .logo-wrap {
  text-align: center;
}

.sidebar .avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 0 auto;
  position: relative;
}
.sidebar .avatar .photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.sidebar.collapsed .avatar {
  width: 60px;
  height: 60px;
}

.name {
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0px;
  font-weight: 500;
}

.main-menu {
  margin-top: 40px;
}
.sidebar-items {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #222;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.5rem 1rem;
  text-decoration: none;
}
.sidebar-items.active,
.sidebar-items:hover {
  color: #04b585;
}
.sidebar-items:not(:last-child) {
  margin-bottom: 10px;
}
.sidebar-items.active::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  width: 6px;
  border-radius: 8px;
  background: #8e8e8e;
}

.main-menu .menu-icon {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 24px;
}

.meta-info {
  color: #222;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  margin-top: 8px;
}
.meta-item {
  display: inline-flex;
  align-items: center;
}
.sidebar-desktop.collapsed .meta-item {
  margin-top: 20px;
}
.sidebar-desktop.collapsed .meta-info {
  flex-flow: column;
}
.sidebar-desktop:not(.collapsed) .meta-item:not(:last-child) {
  margin-right: 30px;
}
.meta-icon {
  stroke-width: 22px;
  margin-right: 5px;
}

.upload-button {
  width: 12px;
  cursor: pointer;
}
.sidebar-desktop.collapsed .upload-button {
  width: 10px;
}

.days-passed {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #222;
  padding: 0 40px;
  margin-top: 16px;
  font-size: 12px;
  width: 100%;
}
.days-passed-icon {
  color: #ffcb00;
  font-size: 36px;
  margin-right: 7px;
}
.sidebar-desktop .collapsed .days-passed-icon {
  font-size: 20px;
}
.sidebar-desktop.collapsed .days-passed {
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  margin-top: 20px;
}
.small-profile {
  margin-top: 40px;
}

#btn-invite-friends {
  border-radius: 8px !important;
  display: block !important;
  color: #222 !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  background-color: #08f7a1 !important;
  border: none !important;
}
#btn-invite-friends span {
  margin-left: 5px;
}

.pipporight {
  text-align: right !important;
}

.path-details {
	background-color: #fff;
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: 100vh;
}

.details-card {
	border: 0;
	border-radius: 10px;
}

.path-responsible-img {
	width: 241px;
	height: 150px;
	overflow: hidden;
	object-fit: cover;
	object-position: top;
}

.details-card .card-title {
	font-weight: 700;
	color: #424242;
}
.details-card .stat-block {
	font-weight: 600;
    color: #222;
    font-size: 14px;
    width: 190px;
    min-height: 42px;
	border: 2px solid #222222;
	border-radius: 4px;
}

.details-card .stat-block span:first-child {
	font-size: 21px;
	color: #222;
	font-weight: 800;
}
.details-card .card-body {
	position: relative;
}

.details-card .sticky-outer-wrapper.active,
.details-card .absolute-top {
	background-color: #f9f9f9;
}

.details-card .profile-card {
	background-color: #EDF1F4;
    padding: 16px;
}
.details-card .profile-card .name {
	font-weight: 700;
	font-size: 18px;
}
.details-card .profile-card .sub-title {
	color: #222;
	font-size: 16px;
}
.details-card .profile-card p {
	font-weight: 500;
	font-size: 14px;
	color: #222;
}
.details-card .profile-card p:last-child {
	margin-bottom: 0;
}
.super-guide-header {
	margin-top: 30px;
	font-weight: 700;
	color: #424242;
	font-size: 21px;
}

.scrollable {
	height:300px;
	overflow-y: scroll;
}
.percorsi-modal-header{
	font-size: 21px;
}
.scope-change-modal{
	max-width: 850px !important;
}
.container {
    max-height: 180px;
    height: 180px;
    background-image:  url("https://res.cloudinary.com/start2impact/image/upload/v1674655505/design/banner-back_uybrg9.svg");
    background-size: cover;
    background-position: center;
    padding: 32px 16px;
    border-radius: 8px;
    margin: 32px 16px;
    text-align: center;
  }

  .text {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    text-align: center;
    margin-bottom: 32px;
  }

  .button {
    margin: auto;
    padding: 8px 16px;
    font-size: 12px;
    background-color: #08F7A1;
    text-transform: uppercase;
    font-weight: 600;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    color: #222222;
  }

  .button:hover {
    color: #222222;
  }
.page404 {
    background: #e6f8f3;
}

.page404 img {
    display: block;
    max-width: 100%;
    height: auto;
}

.page404 span {
    margin-top: 56px;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #04b585;
}

.page404 p {
    margin: 12px 0 0 0;
    margin-top: 12px;
    font-weight: 700;
    font-size: 21px;
    line-height: 26px;
    color: #4d4d4d;
}

.page404 a {
    margin-top: 2rem;
    padding: 1rem 2rem;
    background: #08f7a1;
    border: unset;
    border-radius: 4px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #222222;
    cursor: pointer;
    text-decoration: none;
}

.page404 a:hover {
    color: inherit;
    background: linear-gradient(0.02deg, #007369 0.01%, #08f7a1 99.99%);
}

.navbar {
    display: none !important;
    visibility: hidden;
}

@media (max-width: 1200px) {
    .navbar {
        display: flex !important;
        visibility: visible;
        position: absolute;
        right: 0;
        top: 1rem;
    }
}

.container {
    overflow: hidden;
    border-radius: 8px;
    text-align: center;
    position: relative;
}

.container > div{
    border: none;
}

.popupBody {
    padding: 56px;
}

.closeIcon {
    position: absolute;
    top: 16px;
    right: 16px;
    background:none;
    border: none;
    padding: 0 !important;
}

.imageContainer {
    width: 120px;
    height: 120px;
    margin: auto;
}

.title {
    font-weight: 700;
    font-size: 23px;
    line-height: 28px;
    color: #222222;
    margin: 24px 0 16px;
}

.description {
    display: block;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #4D4D4D;
}

.popupFooter {
    border-top: 1px solid #E6F1F0;
    padding: 16px 24px;
    display: flex;
    justify-content: center;
}

.popupFooter button {
    padding: 8px 16px !important;
    text-transform: uppercase;
}

.popupFooter button:first-child {
    margin-right: 16px;
}
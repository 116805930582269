.home-page {
	min-height: 100vh;
}
.p-32 {
	padding: 32px !important;
}

.home-page .card-block {
	border: none;
	border-radius: 8px !important;
}

.home-page .card-block .card-header {
	background-color: #fff;
	border-radius: calc(10px - 1px) calc(10px - 1px) 0 0;
	border-bottom: 0;
}
.home-page .card-block .card-title {
	font-weight: 800;
	color: #222;
	display: inline-flex;
	align-items: center;
}
.home-page .card-block .card-header .card-title {
	font-size: 14px;
	color: #222;
	font-weight: 600;
}
.home-page .card-block .card-header .card-title .icon {
	font-size: 20px;
}

.home-page .card-block .card-header .delete-btn {
	color: #ee595e;
	text-decoration: none;
	font-weight: 600;
	display: inline-flex;
	align-items: center;
	font-size: 20px;
}

.home-page .card-block .guide-card {
	flex-direction: row;
}

.home-card {
	border: none !important;
	border-radius: 8px !important;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
}
.home-card .card-header {
	background-color: #fff;
	border-radius: calc(10px - 1px) calc(10px - 1px) 0 0;
}

.page-title {
    font-size: 23px !important;
    font-weight: 700;
    line-height: 28px;
    color: #222222 !important;
}


@media (max-width:768px) {
	.pl-only-0 {
		padding-left: 0 !important;
	}
	.pr-only-0 {
		padding-right: 0 !important;
	}

}
.selected-onu-objective {
	border-color: #08F7A1;
}

.scope-change-modal .modal-header {
	padding: 32px;
	border-bottom: none;
}
.scope-change-modal .modal-header .modal-title {
	font-size: 21px;
	line-height: 25.6px;
	font-weight: 700;
}

.scope-change-modal .modal-header {
	border-bottom: 0;
}

.scope-change-modal .modal-body {
	padding-top: 0;
	font-size: 14px;
	line-height: 17px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 0;
}
.scope-change-modal .modal-footer {
	padding-top: 16px;
	padding-left: 32px;
	padding-right: 32px;
	padding-bottom: 32px;
}
.scope-change-modal .modal-footer .save-btn {
	margin-top: 0;
	margin-bottom: 0;
	padding: 16px 24px;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
    border: none;
}
.pageContainer {
    min-height: 100vh;
    background-color: #FFF;
    text-align: center;
    padding-bottom: 80px;
}

.header {
    background-color: #222222;
    color: #FFF;
    font-weight: 700;
}

.headerContentContainer {
    padding: 56px 0px 80px;
}

.headerTitle {
    color: #04B585;
    font-style: normal;
    font-size: 32px;
    line-height: 39px;
    font-weight: inherit;
}

.headerSubtitle {
    font-style: normal;
    font-size: 30px;
    line-height: 49px;
    font-weight: inherit;
}

.pricingTextContainer {
    margin: 32px 0;
}

.barredPrice {
    color: red;
    text-decoration: line-through;
    margin-right: 8px;
}

.pricingText {
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    color: #08F7A1;
}

.headerSecondaryText {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
}

div.servicesList {
    background: rgba(8, 247, 161, 0.15);
    border-radius: 8px;
    max-width: 980px;
    padding: 24px 24px 0;
    color: #04B585;
    margin: 0 auto 32px;
    text-align: left;
}

.servicesListField {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.servicesListField svg {
    min-width: 24px;
    min-height: 24px;
}

.servicesListField p {
    margin-bottom: 0;
    margin-left: 16px;
}

.main {
    padding-top: 80px;
}

.mainTitle {
    font-weight: 700;
    font-size: 40px;
    line-height: 49px;
}

.topicSelector {
    margin: 25px 0 31px;
    text-transform: uppercase;
}

.mastersContainer {
    max-width: 1140px;
    margin: 0 auto;
}

@media screen and (min-width: 768px) {
    .servicesListField:last-child {
        margin-bottom: 0;
    }

    div.servicesList {
        padding: 32px;
    }

    .headerSubtitle {
        font-size: 40px;
    }
}
.guide-page {
	padding-top: 30px;
	padding-bottom: 30px;
	min-height: 100vh;
}

.guide-page .card-block {
	border: none;
	border-radius: 10px !important;
}
.guide-page .card-block .card-header {
	background-color: #fff;
	border-radius: calc(10px - 1px) calc(10px - 1px) 0 0;
	border-bottom: 0;
}

.guide-page .card-block .card-title {
	font-weight: 800;
	color: #222;
	font-family: 'Montserrat', sans-serif;
	display: inline-flex;
	align-items: center;
}

.guide-page .card-block .card-header .card-title {
	font-size: 20px;
	color: #E5E5E5;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
}
.guide-page .card-block .card-header .card-title .icon {
	font-size: 28px;
}

.guide-page .card-block .card-header .delete-btn {
	color: #ee595e;
	text-decoration: none;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	display: inline-flex;
	align-items: center;
}

.guide-page .card-block .guide-card {
	flex-direction: row;
}

.home-guide-list .card-description {
	padding-left: 0 !important;
	margin-top: 10px;
	line-height: 17px;
}

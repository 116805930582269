.notification-toggle  {
    width: 56px;
    height: 56px !important;
    background: #fff !important;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05) !important;
    border-radius: 8px !important;
    border: none !important;
    color: #222 !important;
    position: relative;
}


.notification-toggle-icon {
    font-size: 34px;
}

.notification-badge {
    background-color: #FF5A5F !important;
    position: absolute !important;
    min-width: 16px;
    height: 16px;
    font-size: 10px !important;
    text-align: center;
    border-radius: 50% !important;
    top: 12px !important;
    right: 12px !important;
    padding: 4px !important;
    line-height: 12px;
    
}

.notification .notification-toggle:focus,
.notification .notification-toggle:active,
.notification .notification-toggle:hover {
    background-color: #fff !important;
    color: #8E8E8E !important;
}

.notification.show .notification-toggle:focus,
.notification.show .notification-toggle:active,
.notification.show .notification-toggle:hover,
.notification.show .notification-toggle {
    background: #08F7A1 !important;
    color: #fff !important;
}

.notification.show .notification-toggle .notification-badge {
    display: none;
}

.notification-list {
    width: 350px;
    margin-top: 20px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 8px !important;
    border: none !important;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 15%);
}

.notification-header {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px !important;
    border-bottom: 2px solid #EDF1F4;
}

.notification-header-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222;
}
.notification-link {
    text-decoration: none;
}
.notification-list-wrap a:hover {
    text-decoration: none;
}
.mark-all-read {
    font-size: 10px;
    font-weight: 400;
    text-decoration: none;
    line-height: 12px;
    color: #222;
    padding: 0;
    border: none;
    display: inline-flex;
    align-items: center;
}

.mark-all-read-icon {
    font-size: 17px;
    margin-left: 5px;
}

.notification-item {
    padding: 16px !important;
}
.notification-item:not(:last-child) {
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
}
.notification-img .notification-icon {
    margin-right: 20px;
    font-size: 20px;
    color: #8E8E8E;
}
.notification-item .notification-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222;
    margin: 0;
}
.notification-item .notification-text {
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    color: #222;
    margin: 0;
    inline-size: 140px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    line-break: strict;
    white-space: normal !important;
}
.notification-item.unread {
    background: #E6F8F3;
}
.notification-item.unread .notification-img .notification-icon {
    color: #04B585;
}

.notification-list-wrap {
    max-height: 400px;
    overflow-y: auto;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: transparent !important;
}
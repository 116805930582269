.chartContainer {
	position: relative;
	width: 56px;
	margin: 10px;
}

.chartIcon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 19px;
	color: #ccc;
}

.chartCardButton {
	display: block;
	padding: 8px 16px;
	margin: 0 auto;
	background-color: #fff;
	color: #222;
	border: 1px solid #222222;
	border-radius: 4px;
	font-size: 12px;
	font-weight: 600;
	left: 15px;
	right: 15px;
	bottom: 5px;
	text-align: center;
	text-decoration: none;
	width: 100%;
}

.chartCardButton:hover {
	color: #222;
}


.buttonActive {
	background-color: #08F7A1 !important;
	border: 2px solid #08F7A1 !important;
}

.buttonInactive {
	background-color: #08F7A1 !important;
	border: 2px solid #08F7A1 !important;
	opacity: 0.5;
	pointer-events: none;
	cursor: default;
}

.chartRow {
	height: 100%;
}

.chartRow li {
	font-size: 14px;
	font-weight: 500;
}

.iconGray {
	color: #777 !important;
}
.iconGreen {
	fill: url(#lgrad);
}
.iconRed {
	color: #FF5A5F !important;
}
.iconYellow {
	color: #FFCB00 !important;
}
.iconOrange {
	color: #F2994A !important;
}

.homeGuideChart {
	background: #FFFFFF;
	box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	position: relative;
    padding: 8px 16px;
	gap: 10px;
}

@media (max-width: 768px) {
	.homeGuideChart{
		box-shadow: none !important;
	}
	.collapseLabel li{
		text-align: left;
	}
}
.container {
    padding: 16px;
    background-color: #FFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

/* TODO check style */
span.badge {
	font-size: 1rem;
	font-weight: 500;
}

.cardDescription {
    font-size: 14px; 
}

img.cardImgLeft {
    width: 56px;
	height: 56px;
	border-radius: 8px;
    width: auto;
}

.hr {
    color: #E6F1F0; 
    height: 2px;
    opacity: 1;
}

a.linkButton {
    cursor: pointer;
    text-decoration: none !important;
    color: inherit;
}

div.buttonContainer {
    padding-right: 0;
}

button.button {
    padding: 8px 16px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 4px;
    background-color: #08F7A1;
    color: #222222;
    border: none;
    line-height: 15px;
}
.editable-card {
	border: none !important;
}


.editable-card .card-header {
	border: none;
    margin-bottom: 16px;
	padding: 0px;
}
.editable-card .card-edit-link {
	text-decoration: none;
	font-size: 12px;
	font-weight: 500;
	color: #222;
	display: inline-flex;
	align-items: center;
	padding: 0;
}

.editable-card .card-edit-link .icon {
	margin-left: 10px;
	font-size: 14px;
	width: 16px;
	height: 16px;
	line-height: 16px;
}

.editable-card .card-body {
    width: 100%;
    padding: 16px;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    background: #EDF1F4;
    box-shadow: 0px 4px 16px rgb(0 0 0 / 5%);
    border-radius: 16px;
}

.editable-card .card-body .icon {
	margin-right: 10px;
	font-size: 24px;
	color: #f2994a;
}

.editable-card .progress {
	height: 1.6rem;
	border-radius: 4px;
	background: #fff;
}

.editable-card .progress-bar {
	background: linear-gradient(45.72deg, #007369 0%, #08F7A1 100%);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	font-size: 16px;
	font-weight: 400;
}

.editable-card .list-item .icon-block {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	margin: 0 auto;
	border-radius: 5px;
	font-size: 16px;
	margin-right: 16px;
	margin-left: 0;
	flex-shrink: 0;
}

.editable-card .list-item .icon-block:last-child {
	margin-right: 0;
}
@media (max-width: 768px) {
	.editable-card .list-item.image-list {
		flex-wrap: nowrap !important;
		overflow-x: auto ;
	}
	.editable-card{
		padding-bottom: 24px !important;
	}

}

.editable-card .list-item.image-list {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.editable-card .list-item.image-list.empty-image {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
}


.editable-card .list-item {
	font-size: 16px !important;
}
.editable-card .list-item .icon-block .icon {
	margin-right: 0;
	color: #5ec868;
	font-size: 28px;
}
.square {
	border: 2px dashed #b3aeae;
}

.editable-card .list-item .icon-block.active {
	background-color: #5ec868 !important;
}

.editable-card .list-item .icon-block.active .icon {
	color: #fff !important;
}
.editable-card .title-header{
	font-size: 16px;
    line-height: 20px;
    font-weight: 500;
}
.editable-card .text-end{
	font-size: 12px;
}

@media (min-width: 1200px) {
	.home-card .editable-card:not(.pr-0) {
		padding-right: 39.33px;
	}
	.editable-card:not(.pl-0) {
		padding-left: 27.67px;
	}
}